import { auth } from "@/firebase";
import { ref } from "vue";

import profileData, { RiderUser, RiderProfile } from "./ProfileData";
import passengerData, { PassengerInfo } from "./PassengerData";
import rideData, { RideInfo } from "./RideData";
import profiles from "./ProfileData";
import { toDate } from "@/filters/dateFilters"

export const userProfile = ref({} as RiderUser);
export const initialised = ref(false);
export const isLoggedIn = ref(false);

export const userRideInfo = ref();
export const userPassengerInfo = ref();

const ADMIN_LIST = [
  'bennyincan@gmail.com',
  'rosefaith28@yahoo.ca'
]

const fetchUserProfile = async (email: string, user: any): Promise<RiderUser> => {
  const profile: RiderProfile = await profileData.get(email);
  const user_profile: RiderUser = {
    profile: profile,
    user: user,
    isAdmin: ADMIN_LIST.includes(email),
  }
  return user_profile;
}

auth.onAuthStateChanged(async (firebaseUser) => {
  if (firebaseUser?.email) {
    userProfile.value = await fetchUserProfile(firebaseUser.email, firebaseUser);

    const singleRideInfo = await rideData.getByEmail(firebaseUser.email);
    if (singleRideInfo) {
      singleRideInfo.lastUpdated = toDate(singleRideInfo.lastUpdated);
      userRideInfo.value = singleRideInfo;
    }
    else {
      userRideInfo.value = null;
    }

    const singlePassengerInfo = await passengerData.getByEmail(firebaseUser.email);
    if (singlePassengerInfo) {
      singlePassengerInfo.lastUpdated = toDate(singlePassengerInfo.lastUpdated);
      userPassengerInfo.value = singlePassengerInfo;
    }
    else {
      userPassengerInfo.value = null;
    }

    isLoggedIn.value = true;
  }
  else {
    userRideInfo.value = null;
    userPassengerInfo.value = null;
    isLoggedIn.value = false;
  }
  initialised.value = true;
});

export async function createUserRideInfo(ride: RideInfo) {
  const currentUser = userProfile.value;
  ride.driverID = currentUser.user.email;
  ride.plateNumber = ride.plateNumber.toUpperCase();
  const new_record = await rideData.create(ride);
  ride.id = new_record.id;
  userRideInfo.value = ride;
}

export async function deleteUserRideInfo() {
  await rideData.delete(userRideInfo.value.id);
  userRideInfo.value = null;
}

export async function createUserPassengerInfo(passenger: PassengerInfo) {
  const currentUser = userProfile.value;
  passenger.passengerID = currentUser.user.email;
  const new_record = await passengerData.create(passenger);
  passenger.id = new_record.id;
  userPassengerInfo.value = passenger;
}

export async function deleteUserPassengerInfo() {
  await passengerData.delete(userPassengerInfo.value.id);
  userPassengerInfo.value = null;
}

export async function logout() {
  await auth.signOut();
  isLoggedIn.value = false;
}

export async function useAuthenticate(email: string, fbUser: any) {
  userProfile.value = await fetchUserProfile(email, fbUser);
  isLoggedIn.value = true;
}

export async function updateProfileInfo(email: string, profile: RiderProfile) {
  console.log(window.location.host)
  profiles.update(email, profile);
  // update ride info belong to this user
  rideData.getByEmail(email).then((data) => {
    if (data) {
      data.name = profile.name;
      data.phone = profile.phone;
      rideData.update(data.id, data as RideInfo);
    }
  });
  // update passenger info belong to this user
  passengerData.getByEmail(email).then((data) => {
    if (data) {
      data.name = profile.name;
      data.phone = profile.phone;
      passengerData.update(data.id, data as PassengerInfo);
    }
  });
}

export async function useReset(email: string) {
  await auth.sendPasswordResetEmail(email, { url: `${window.location.origin}/login?email=${email}` });
}