import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas, faTimes } from "@fortawesome/free-solid-svg-icons";
// import { far, faWindowClose } from "@fortawesome/free-regular-svg-icons";
import FontAwesomeIcon from "../lib/FontAwesomeIcon.vue";

//import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
//import { faMagnifyingGlass} from '@fortawesome/pro-thin-svg-icons'
import { faMagnifyingGlass, faArrowRightToArc, faLocationDot, faPhone, faIdCard, faLock, faTrashCan} from '@fortawesome/pro-light-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

// import {
//   faFacebookF,
//   faLinkedinIn,
//   faTwitter,
//   faInstagram
// } from "@fortawesome/free-brands-svg-icons";

library.add(
    faMagnifyingGlass,
    faArrowRightToArc,
    faLocationDot,
    faPhone,
    faIdCard,
    faLock,
    faFacebookF,
    faTrashCan,
);

export { FontAwesomeIcon };