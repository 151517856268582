import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCOHkMftj15xt4bWTHen-IOe2Sgr9N6nE0",
    authDomain: "lcarideshare.firebaseapp.com",
    projectId: "lcarideshare",
    storageBucket: "lcarideshare.appspot.com",
    messagingSenderId: "289737924315",
    appId: "1:289737924315:web:a6ad78fdd492c8a1d2b6fe",
    measurementId: "G-RZTJRKGHK5"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const repo = firebase.firestore();