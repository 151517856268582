import {repo} from "@/firebase";
const profiles = repo.collection('profiles')

export const ALL_NEIGHBORHOODS:any = {
  'Nutana': [52.1221521,-106.6704841],
  'Varsity View': [52.121788,-106.649316],
  'Brighton': [52.1253741,-106.5748077],
  'Briarwood': [52.1080967,-106.5722697],
  'College Park': [52.1220431,-106.6053403],
  'College Park East': [52.1205419,-106.5820403],
  'Hillcrest Management Area': [52.1044819,-106.5568722],
  'Lakeridge': [52.095372,-106.5880114],
  'Lakewood Suburban Centre': [52.0979419,-106.5724026],
  'Lakeview': [52.0971288,-106.6021846],
  'Rosewood': [52.093481,-106.564505],
  'Wildwood': [52.1075681,-106.6062095],
  'Adelaide/Churchill': [52.0985545,-106.6489634],
  'Avalon': [52.0950726,-106.6671933],
  'Brevoort Park': [52.1098075,-106.6222825],
  'Buena Vista': [52.1110539,-106.6779297],
  'Eastview': [52.095305,-106.622418],
  'Exhibition': [52.1000352,-106.6824325],
  'Greystone Heights': [52.1183041,-106.6221834],
  'Grosvenor Park': [52.1183351,-106.6333291],
  'Haultain': [52.1110509,-106.6562525],
  'Holliston': [52.1083548,-106.6392213],
  'Nutana Park': [52.0962234,-106.6377537],
  'Nutana Suburban Centre': [52.1037245,-106.6224891],
  'Queen Elizabeth': [52.1037858,-106.664493],
  'Stonebridge': [52.079849,-106.642635],
  'The Willows': [52.0777235,-106.6671952],
  'Arbor Creek': [52.1334787,-106.5866401],
  'Aspen Ridge': [52.1765735,-106.5589546],
  'Erindale': [52.1405144,-106.5796918],
  'Evergreen': [52.1680201,-106.5849594],
  'Forest Grove': [52.1424572,-106.5981451],
  'Silverspring': [52.1597644,-106.599974],
  'Sutherland': [52.1395363,-106.6134387],
  'Sutherland Industrial': [52.134372,-106.595849],
  'University Heights': [52.1474767,-106.5855392],
  'Willowgrove': [52.1464059,-106.5586004],
  'Blairmore Suburban Centre': [52.1262296,-106.7674169],
  'Kensington': [52.1402759,-106.7636835],
  'Confederation Park': [52.1384345,-106.7565471],
  'Dundonald': [52.1475304,-106.7390704],
  'Fairhaven': [52.1223451,-106.7403819],
  'Hampton Village': [52.1547026,-106.7316779],
  'Holiday Park': [52.1105767,-106.696997],
  'Hudson Bay Park': [52.14597,-106.7024144],
  'Massey Place': [52.1398244,-106.7227977],
  'Meadowgreen': [52.1227684,-106.7215522],
  'Montgomery Place': [52.1114406,-106.7536292],
  'Mount Royal': [52.13549,-106.7227317],
  'Pacific Heights': [52.1332232,-106.7494599],
  'Parkridge': [52.1235447,-106.764441],
  'Westview': [52.1475327,-106.7141644],
  'Caswell Hill': [52.1365836,-106.6845226],
  'City Park': [52.137465,-106.6617026],
  'Downtown': [52.128573,-106.6657366],
  'King George': [52.1181312,-106.69244],
  'Pleasant Hill': [52.1234388,-106.7015209],
  'Riversdale': [52.1253812,-106.6867779],
  'Westmount': [52.1355237,-106.6964455],
  'Kelsey-Woodlawn': [52.1509263,-106.6672559],
  'Lawson Heights': [52.1694446,-106.639704],
  'Mayfair': [52.1493655,-106.6805204],
  'North Park': [52.147778,-106.650278],
  'River Heights': [52.1610452,-106.6374935],
  'Richmond Heights': [52.1538706,-106.6463418],
  'Silverwood Heights': [52.1814588,-106.6398785],
}

export interface RiderUser {
  profile: RiderProfile,
  user: any,
  isAdmin: boolean,
}

export interface RiderProfile {
    name: string,
    phone: string,
    lastUpdated?: Date,
}

class ProfileData {
  getAll() {
    return profiles;
  }
  async get(userID: string): Promise<any> {
    const profile = await profiles.doc(userID).get();
    return profile.exists ? profile.data() : null;
  }
  // Update record base on the userID
  update(userID: string, profile: RiderProfile) {
    profile.lastUpdated = new Date();
    return profiles.doc(userID).update(profile);
  }
  async exist(userID: string): Promise<boolean> {
    const docSnap = await profiles.doc(userID).get();
    return docSnap.exists;
  }
  // Create the profile base on the upload
  createProfileEntry(userID: string) {
    profiles.doc(userID).get().then((docSnap) => {
      if(!docSnap.exists) {
        const empty_profile = {
          lastUpdated: new Date()
        }
        profiles.doc(userID).set(empty_profile as RiderProfile);
      }
    })
  }
}
export default new ProfileData();