import {repo} from "@/firebase";
import { ref } from "vue";
import { ALL_NEIGHBORHOODS } from "./ProfileData";
const collection = repo.collection('passengers')

export interface PassengerInfo {
  id?: string,
  passengerID: string,
  lat: number,
  lng: number,
  neighborhood: string,
  name: string,
  phone: string,
  seatsNeeded: number,
  schedule: string[],
  lastUpdated: Date,
}

class PassengerData {
  getAll() {
    return collection;
  }
  async get(id: string): Promise<any> {
      const profile = await collection.doc(id).get();
      return profile.exists ? profile.data() : null;
  }
  // Create record with a random id
  create(record: PassengerInfo) {
    const neighborHoodCoordinate = ALL_NEIGHBORHOODS[record.neighborhood]
    if (neighborHoodCoordinate)
    {
      record.lat = neighborHoodCoordinate[0];
      record.lng = neighborHoodCoordinate[1];
    }
    record.lastUpdated = new Date();
    return collection.add(record);
  }
  // Update record base on id
  update(id: string, record: PassengerInfo) {
    record.lastUpdated = new Date();
    console.log('before', record)
    return collection.doc(id).update(record);
  }
  // Delete record base on id
  delete(id: string) {
    return collection.doc(id).delete();
  }

  async getByEmail(email: string) { 
    const querySnapshot = await collection.where('passengerID', '==', email).limit(1).get()
    if (querySnapshot.size > 0)
    {
      const record = querySnapshot.docs[0].data();
      record.id = querySnapshot.docs[0].id;
      return record;
    }
    else
      return null;
  }

  useLoadAll() {
    const emptyColl: PassengerInfo[] = [];
    const all_records = ref(emptyColl);
    collection.onSnapshot(snapshot => {
        all_records.value = snapshot.docs.map(doc => {
        const record = doc.data();
        record.id = doc.id;
        return record as PassengerInfo;
      })
    })
    return all_records;
  }
}
export default new PassengerData();