import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { initialised, isLoggedIn } from "@/services/AuthService";
import { watch } from 'vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    component: () => import ('@/pages/HomePage.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/dashboard',
    component: () => import ('@/pages/DashboardPage.vue')
  },
  {
    path: '/admin',
    component: () => import ('@/pages/AdminPage.vue')
  },
  {
    path: '/profile',
    component: () => import ('@/pages/MyProfilePage.vue')
  },
  {
    path: '/map',
    component: () => import ('@/pages/RideMapPage.vue')
  },
  {
    path: '/post-a-ride',
    component: () => import ('@/pages/PostARidePage.vue')
  },
  {
    path: '/join-rider-list',
    component: () => import ('@/pages/PostAsPassengerPage.vue')
  },
  {
    path: '/login',
    component: () => import ('@/pages/SignInPage.vue'),
    meta: {
      public: true
    },
    beforeEnter: async (to, from, next) => {
      if (isLoggedIn.value) {
          return next("/dashboard");
      }
      next();
    },
  },
  {
    path: '/reset',
    component: () => import ('@/pages/ResetPasswordPage.vue'),
    meta: {
      public: true
    },
    beforeEnter: async (to, from, next) => {
      if (isLoggedIn.value) {
          return next("/dashboard");
      }
      next();
    },
  },
  {
    path: '/registration',
    component: () => import ('@/pages/RegistrationPage.vue'),
    meta: {
      public: true
    },
    beforeEnter: async (to, from, next) => {
      if (isLoggedIn.value) {
          return next("/dashboard");
      }
      next();
    },
  },
  {
    path: "/:catchAll(.*)",
    name: '404',
    component: () => import ('@/pages/404Page.vue'),
    meta: {
      public: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
})

router.beforeEach((to, _, next) => {
  if (initialised.value) {
    if (!to.matched.some(record => record.meta.public) && !isLoggedIn.value) {
      return next("/login?returnURL=" + to.path);
    }
  
    next();
  }
  else {
    watch(
      () => initialised.value,
      (newVal) => {
        
        if (newVal) {
          if (!to.matched.some(record => record.meta.public) && !isLoggedIn.value) {
            return next("/login?returnURL=" + to.path);
          }

          next();
        }
      }
    );
  }
});

export default router
