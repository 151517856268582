import {repo} from "@/firebase";
import { Ref, ref } from "vue";
import { ALL_NEIGHBORHOODS } from '@/services/ProfileData'

const rides = repo.collection('rides')

export interface RideInfo {
  id?: string,
  driverID: string,
  lat: number,
  lng: number,
  neighborhood: string,
  name: string,
  phone: string,
  plateNumber: string,
  seatsAvailable: number,
  schedule: string[],
  lastUpdated: Date,
}

class RideData {
  getAll() {
      return rides;
  }
  async get(id: string): Promise<any> {
      const profile = await rides.doc(id).get();
      return profile.exists ? profile.data() : null;
  }
  // Create record with a random id
  create(record: RideInfo) {
    const neighborHoodCoordinate = ALL_NEIGHBORHOODS[record.neighborhood]
    if (neighborHoodCoordinate)
    {
      record.lat = neighborHoodCoordinate[0];
      record.lng = neighborHoodCoordinate[1];
    }

    record.lastUpdated = new Date();
    return rides.add(record);
  }
  // Update record base on id
  update(id: string, record: RideInfo) {
    record.lastUpdated = new Date();
    return rides.doc(id).update(record);
  }

  // Delete record base on id
  delete(id: string) {
    return rides.doc(id).delete();
  }

  async getByEmail(email: string) { 
    const querySnapshot = await rides.where('driverID', '==', email).limit(1).get();
    if (querySnapshot.size > 0)
    {
      const record = querySnapshot.docs[0].data();
      record.id = querySnapshot.docs[0].id;
      return record;
    }
    else
      return null;
  }

  useLoadAll(): Ref<RideInfo[]> {
    const emptyColl: RideInfo[] = [];
    const collection = ref(emptyColl);
    rides.onSnapshot(snapshot => {
      collection.value = snapshot.docs.map(doc => {
        const record = doc.data();
        record.id = doc.id;
        return record as RideInfo;
      })
    })
    return collection;
  }
}
export default new RideData();