export function toDate(timeStamp: any): Date {
    if (timeStamp?.seconds)
        return new Date(timeStamp.seconds * 1000);
    else
        return new Date();
}

export function toDateString(datetime: Date): string {
    if (datetime)
        return datetime.toLocaleDateString();
    else
        return ''
}