import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import { plugin, defaultConfig } from '@formkit/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import 'tailwindcss/base.css'
/* Basic CSS for apps built with Ionic */
//import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
import 'tailwindcss/components.css'
/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';
import 'tailwindcss/utilities.css'
// main.js or formkit.config.ts
import '@formkit/themes/genesis'
import './index.css'

/* Theme variables */
import './theme/variables.css';
import { FontAwesomeIcon } from './plugins/font-awesome';

const app = createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(IonicVue)
  .use(plugin, defaultConfig)
  .use(router);
  
router.isReady().then(() => {
  app.mount('#app');
});